.copy-button {
  /* Copy the styles from the button in the Post.css file */
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  float: left;
  margin-top: 10px; /* Add margin to space it further */
}


.data-item {
  margin-top: 10px;
  white-space: pre-wrap; /* Add this line */
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.header-title {
  text-align: left;
  padding-left: 30px;
}

.warning {
  font-weight: bold;
  color: red;
}
